<template>
  <div class="d-flex flex-column gap-3">
    <v-card
      elevation="0"
      class="d-flex align-center gap-2 flex-wrap rounded-lg pa-2"
    >
      <div>
        <v-img
          class="rounded-lg"
          :aspect-ratio="16 / 9"
          :src="party?.cover"
          height="60px"
          :width="`${60 * (16 / 9)}px`"
        />
      </div>
      <div class="flex-grow-1">
        <p class="mb-0 text-overline lh-1">
          {{ party?.date | date("DD/MM/YY - HH:mm") }}
        </p>
        <h6 class="mb-0">{{ party?.name }}</h6>
      </div>
      <div class="flex-grow-1">
        <v-btn
          v-if="hasPermission(262144)"
          color="primary"
          :large="!$vuetify.breakpoint.xsOnly"
          @click="scan()"
          block
        >
          <v-icon left small>mdi-qrcode-scan</v-icon>
          Validar Ingressos
        </v-btn>
      </div>
    </v-card>

    <!-- <entries-sessions :partyId="partyId" /> -->
    <entries-table :partyId="partyId" :party="party" v-if="party" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EntriesSessions from "../../../components/admin/party/entrance/EntriesSessions.vue";
import EntriesTable from "../../../components/admin/party/entrance/EntriesTable.vue";

export default {
  components: { EntriesTable, EntriesSessions },
  metaInfo: { title: "Gerenciar Entrada" },
  data: () => ({ party: null }),
  methods: {
    ...mapActions("organization", ["partyById"]),
    async getParty() {
      this.party = await this.partyById(this.partyId);
      this.$root.$emit("setImgBg", this.party.cover);
    },
    scan() {
      this.$router.push({
        name: "admin.entrance.scan",
        params: { partyId: this.partyId },
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    partyId() {
      return this.$route.params.partyId;
    },
  },

  watch: {
    "selectedOrganization.id"() {
      this.$router.push({ name: "admin.entrance" });
    },
  },
  mounted() {
    this.getParty();
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
};
</script>
