<template>
  <v-card :loading="loading">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="sessions"
        :loading="loading"
        dense
      >
        <template v-slot:top>
          <div class="d-flex align-center pa-2">
            <h6>Leitores Externo</h6>
            <v-spacer />
            <v-btn
              small
              color="primary"
              @click="addSession"
              :disabled="loading"
              class="ml-2"
            >
              <v-icon left>mdi-plus</v-icon>
              Adicionar
            </v-btn>
            <v-btn
              icon
              @click="getSessions"
              :loading="loading"
              :disabled="refreshDisabled"
              class="ml-2"
              small
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";

export default {
  data() {
    return {
      loading: false,
      refreshDisabled: false,
      sessions: [],
      headers: [
        { text: "Nome", value: "Owner.name" },
        { text: "Horário", value: "time" },
        { text: "Setores", value: "TicketGroup" },
      ],
    };
  },
  methods: {
    enableRefresh(ms) {
      this.refreshDisabled = true;
      setTimeout(() => {
        this.refreshDisabled = false;
      }, ms);
    },
    async getSessions() {
      try {
        this.loading = true;
        this.refreshDisabled = true;
        var response = await ORGANIZATION.party.entrance.session.getAll(
          this.selectedOrganization.id,
          this.partyId
        );
        this.sessions = response.sessions;
        this.enableRefresh(60000);
      } catch (error) {
        this.enableRefresh(5000);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    addSession() {
      this.$emit("entry-session-modal", { partyId: this.partyId });
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  props: {
    partyId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.getSessions();
  },
};
</script>
